import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ColorService {
  colors = ["#2b88d2", "#f73ee9", "#8bc34a", "#607d8b", "#3cbd25", "#d6be14"];

  get(count: number): string[] {
    const result = [...this.colors];
    for (let i = 0; i < this.colors.length - count; i++) {
      const index = this.randomIntFromInterval(0, result.length - 1);
      result.splice(index, 1);
    }
    return result;
  }

  private randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
